import {Layout} from 'antd';
import styled from 'styled-components';

const {Content} = Layout;

export default styled(Content)`
  /* 
    display:flex;
    flex-direction: column; 
    */
  width: 100vw;
  max-width:1200px;
  position: relative;
  margin:40px auto;
  // background: ${({theme}) => theme.colors.background};

  margin-top: ${({theme}) => theme.header.height}px;
  padding: ${({theme}) => theme.main.padding}px ${({theme}) => theme.content.paddingHorizontal};
  @media screen and (max-width: 1200px){
    margin-top: 20px;
  }
`;
