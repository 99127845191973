import React, {useEffect, useState} from 'react';
import {DefaultTheme, ThemeProvider as StyledComponenThemeProvider} from 'styled-components';

import useResponsive from '../hooks/useResponsive';
import defaultTheme from './index';
import mobileTheme from './mobileTheme';

interface ThemeSelectorProps {
  setTheme: (themeObj: DefaultTheme) => void;
}

// SETS MOBILE THEME CONFIG IF NECESSARY
const ThemeSelector: React.FC<ThemeSelectorProps> = ({children, setTheme}) => {
  const {xs} = useResponsive();

  useEffect(() => {
    if (xs) {
      setTheme({
        ...defaultTheme,
        main: {
          ...defaultTheme.main,
          ...mobileTheme.main,
        },
      });
    } else {
      setTheme(defaultTheme);
    }
  }, [xs, setTheme]);

  return <>{children}</>;
};

const ThemeProvider: React.FC = ({children}) => {
  const [theme, setTheme] = useState(defaultTheme);

  return (
    <StyledComponenThemeProvider theme={theme}>
      <ThemeSelector setTheme={setTheme}>{children}</ThemeSelector>
    </StyledComponenThemeProvider>
  );
};

// ThemeSelector.propTypes = {
//     children: PropTypes.node,
//     setTheme: PropTypes.func.isRequired,
// };
// ThemeSelector.defaultProps = {
//     children: () => <div />,
// };

// ThemeProvider.propTypes = {
//     children: PropTypes.node,
// };
// ThemeProvider.defaultProps = {
//     children: () => <div />,
// };

export default ThemeProvider;
