import styled from 'styled-components';
import Layout from '../index';

const {Header} = Layout;

export const HeaderContainer = styled.div`
  z-index: 1000;
  position: fixed;
  width: 100vw;
  @media screen and (max-width: 1200px){
    position: relative;
  }
 
`;

export const StyledHeader = styled(Header)`
  overflow: hidden;
  height: ${({theme}) => theme.header.height}px;
  padding: ${({theme}) => theme.main.padding}px;

  box-shadow: ${({theme}) => theme.main.shadowHover};
  background-color: ${({theme}) => theme.colors.light};
  border-bottom: ${({theme}) => `1px solid ${theme.colors.lightTransparent}`};

  transition: background-color ${({theme}) => theme.main.defaultAnimation};
 
`;
export const Logo = styled.img`
  max-height: 70px;
`;
