import React from 'react';
import {useTheme} from 'styled-components';
import {
  faAngleDown,
  faCheckCircle,
  faExclamationCircle,
  faFilePdf,
  faPen,
  faUser,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import {IconDefinition, library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import {Color} from '../../theme';

library.add(
  faUser as IconDefinition,
  faAngleDown as IconDefinition,
  faCheckCircle as IconDefinition,
  faPen as IconDefinition,
  faFilePdf as IconDefinition,
  faExclamationCircle as IconDefinition,
  faEye as IconDefinition,
);

interface Props extends FontAwesomeIconProps {
  color?: Color;
  margin?: boolean;
  className?: string;
}

const Icon: React.FC<Props> = ({color, margin = true, className = '', ...props}) => {
  const theme = useTheme();
  const themedColor = theme.getThemedColor(color);

  return (
    <FontAwesomeIcon
      color={themedColor}
      className={`${className} ${margin ? 'mr' : ''}`}
      {...props}
    />
  );
};

export default Icon;
