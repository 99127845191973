import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Row, Col, Form, Input, notification} from 'antd';
import Button from '../../components/Button';
import Text from '../../components/Text';
import userApi from '../../Api/User';
import {UpdateUserPayload} from '../../Api/User/types';
import {setUser} from '../../store/reducers/userSlice';

interface Props {
  onSuccess: () => any;
}

const UpdateUserForm: React.FC<Props> = ({onSuccess}) => {
  const dispatch = useDispatch();
  const {user} = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);

  const updateUser = async (updateUserPayload: UpdateUserPayload) => {   
    try {
      setLoading(true);
      await userApi.updateUser(user.financialInstitutionId ,updateUserPayload);
      notification.success({message: 'Hesabınız başarıyla güncellendi.'});
      if (onSuccess) await onSuccess();
      const response = await userApi.fetchUser();
      if (response) {
        dispatch(setUser(response.data.financialInstitutionUser));
      }
      setLoading(false);
    } catch  {
      // eslint-disable-next-line no-console
      
    }
  };
  

  return (
    <Form name="updateUser" onFinish={updateUser} initialValues={user}>
      <Row className="mt" gutter={20}>
        <Col xs={24} lg={12} xl={8}>
          <Text color="smoke" block>
            Adı:{' '}
          </Text>
          <Form.Item name="name" rules={[{required: true, message: 'Lütfen isminizi giriniz!'}]}>
            <Input className="w-100" />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12} xl={8}>
          <Text color="smoke" block>
            Soyadı:{' '}
          </Text>
          <Form.Item name="surname" rules={[{required: true, message: 'Lütfen isminizi giriniz!'}]}>
            <Input className="w-100" />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12} xl={8}>
          <Text color="smoke" block>
            Unvan:{' '}
          </Text>
          <Text bold>{user.corporationName}</Text>
        </Col>

        <Col xs={24} lg={12} xl={8}>
          <Text color="smoke" block>
            Telefon Numarası:{' '}
          </Text>
          <Form.Item
            name="gsmNumber"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  const patt = new RegExp('(05|5)[0-9][0-9][0-9]([0-9]){6}');
                  return patt.test(value) && value.length < 12
                    ? Promise.resolve()
                    : Promise.reject(new Error('Lütfen geçerli bir telefon numaranızı giriniz!'));
                },
              },
            ]}
          >
            <Input className="w-100" />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12} xl={8}>
          <Text color="smoke" block>
            Şifre:{' '}
          </Text>
          <Form.Item name="password">
            <Input className="w-100" />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12} xl={8}>
          <Text color="smoke" block>
            E-posta adresi:{' '}
          </Text>
          <Text bold>{user.email}</Text>
        </Col>

        <Col xs={24} lg={12} xl={8}>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" loading={loading} block>
              Güncelle
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UpdateUserForm;
