import styled from 'styled-components';

export const CardBox = styled.div `
@media screen and (max-width: 1200px) {
   
    .ant-col-7 {
        display: block;
        flex: 100%;
        max-width: 100%;
    }
  
    .ant-col-17 {
        display: block;
        flex: 100%;
        max-width: 100%;
    }
  }

`

export const TableBox = styled.div `

.ant-table-thead > tr > th{
    background: #fafbff;
    color:#001444;
}
.ant-table-cell-row-hover{
    background: #fafbff !important;
}
.ant-table-tbody{

}
`

export const Header = styled.div `
.head-title{
    margin: 33px 0 33px 0;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #001444;
}
`
export const LeftSideBox = styled.div`
    display: grid;
    width: 320px;
    margin-right: 20px;
    .head-title {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #001444;
    }
    .status-box {
        width: 320px;
        height: 64px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 32px 0px 0px;
        gap: 49px;
        padding: 16px;
        object-fit: contain;
        background-color: #f4f4f4;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
       
    }
    .new-request{
        background-color: #effff0;
        .status-title{
            color:green;
        }
    }
    .sales-person{
        background-color:#a9bfdd;
        .status-title{
            color:blue;
        }
    }
    .offered{
        background-color:#e9b692d9;
        .status-title{
            color:#f5771fd9;
        }
    }
    .approved{
        background-color:#e092e9d9;
        .status-title{
            color:#d335e5d9;
        }
    }
    .status-title {
        flex-grow: 0;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        margin-left: 8px;
        letter-spacing: normal;
        text-align: left;
        color: #131112;
    }
    .item-title {
        width: 320px;
        flex-grow: 0;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #001444;
    }
    .item-value {
        flex-grow: 0;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #131112;
    }
    .item-row {
        margin-bottom: 15px;
        border-bottom: 1px dashed #efefef;
    }
    .item-box {
        width: 320px;
        height: max-content;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        padding: 16px;
        border: solid 1px #eef4ff;
        background-color: #fff;
    }
    @media screen and (max-width: 1200px) {
        display: block;
        width: 100%;
        .status-box{
            width: 100%;
            height: auto;
        }
        .ant-col-7 {
            display: block;
            flex: 100%;
            max-width: 100%;
        }
        .item-box{
            width: 100%;
        }
        .ant-col-17 {
            display: block;
            flex: 100%;
            max-width: 100%;
        }
      }
`;
export const BottomSideBox = styled.div`
width:100%;
padding: 0 0 18px;
border-radius: 8px;
box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.25);
border: solid 1px #eef4ff;
background-color: #fff;
position: fixed;
bottom: 20px;
max-width: 800px;
margin: 0 0 13px;
padding: 18px 30.5px 18px 28.1px;
z-index: 1;

    .header-box{
        display: flex;
        padding:0;
        background-color: #fafbff;
        flex-direction: row;
        justify-content: left;
        width: 100%;
    }
    .bid-amount{
        font-size: 18px;
        border-bottom: double;
    }
    .bid-amount span{
        font-weight: 600;
    }
    .header-title{
        flex-grow: 0;
        margin: 12.5px 84.4px 12.5px 0;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #001444;
        min-width:234px;
        white-space: nowrap;
    }
    .header-table{
        display: flex;
        width: 100%;
        justify-content: space-around;
        div{
            margin:0;
            padding:0;
            display: flex;
            flex-direction: column;
            text-align: right;
            .text-title{
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: center;
                color: #001444;
            }
        }
    }
    @media screen and (max-width: 1200px){
       
        position: fixed;
         bottom: auto;
        max-width: 300px;
        right: 0;
        height: auto;
        top: 150px;
        .header-box{
            display: block;
            margin-top: 15px;
        }
        .header-table{
            display: block;
            width: 100%;
        }
        .header-table div .text-title{ text-align: left; 
            margin-bottom: 10px;}
        .header-table div{text-align: left;}
    }
`;

