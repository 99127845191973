import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {Props} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default styled<React.FC<Props>>(Button as any)`
  /* box-shadow: ${({theme, type}) => type !== 'link' && theme.main.shadow}; */
  user-select: none !important;
  box-shadow: none;

  &.ant-btn-lg {
    padding-left: ${({theme}) => theme.main.paddingBig}px;
    padding-right: ${({theme}) => theme.main.paddingBig}px;
  }

  background: ${({type, theme}) => {
    switch (type) {
      case 'secondary':
        return theme.colors.secondary;
      case 'default-secondary':
        return theme.colors.secondaryLight;
      default:
        return '';
    }
  }};
  color: ${({type, theme}) => {
    switch (type) {
      case 'secondary':
        return theme.colors.text;
      case 'default-secondary':
        return theme.colors.secondaryDark;
      default:
        return '';
    }
  }};

  border-color: ${({type, theme}) => {
    switch (type) {
      case 'secondary':
        return theme.colors.secondary;
      case 'default-secondary':
        return theme.colors.secondaryLight;
      default:
        return '';
    }
  }};

  &.ant-btn:hover,
  &.ant-btn:focus {
    background: ${({type, theme}) => {
      switch (type) {
        case 'secondary':
          return theme.colors.secondaryLight;
        case 'default-secondary':
          return theme.colors.secondary;
        default:
          return '';
      }
    }};
    color: ${({type, theme}) => {
      switch (type) {
        case 'secondary':
          return theme.colors.dark;
        case 'default-secondary':
          return '#fff';
        default:
          return '';
      }
    }};
    border-color: ${({type, theme}) => {
      switch (type) {
        case 'primary':
          return theme.colors.primaryDark;
        case 'secondary':
          return theme.colors.secondaryLight;
        case 'default-secondary':
          return theme.colors.secondary;
        default:
          return '';
      }
    }};
  }
`;
