import styled from 'styled-components';
import Props from './types';

export default styled.div<Props>`
  max-width: ${({theme}) => theme.main.limitedContentWidth}px;
  margin: ${({type}) => (type === 'inner' ? 'none' : 'auto')};

  padding: ${({theme, verticalPadding}) => verticalPadding && theme.main.paddingBig}px
    ${({theme}) => theme.pageContent.paddingHorizontal}px;

  padding-left: ${({theme}) => theme.pageContent.paddingHorizontal}px;
  padding-right: ${({theme}) => theme.pageContent.paddingHorizontal}px;
`;
