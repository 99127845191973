/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  // discountInvoices: '',
  discountInvoices: '',
  financial: {},
  finanStatus: '',
  invoicesList:[],
  invoicesDue :[],
  invoicesDueId :[],
  invoicesPaidId : [],
  invoicesPaid :[],
  commissionRate:0,
  interestRate:0,
  buyerList :[],
  pNumber:1,
  pSize:10
};

const fundSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {
    setDiscountInvoices(state, action) {
      state.discountInvoices = action.payload.sort((a, b) => b.applicationNumber - a.applicationNumber);
    },
    setFinancialData(state, action) {
      state.financial = action.payload;
     
    },
    setFinancialStatus(state, action) {
      state.finanStatus = action.payload;
      
    },
    setInvoicesList(state, action) {
      state.invoicesList = action.payload;
    },
    setInvoicesDue(state, action){
      state.invoicesDue = action.payload;
    },
    setInvoicesDueId(state, action){
      state.invoicesDueId = action.payload;
    },
    setCommissionRate(state, action){
      state.commissionRate = action.payload;
    },
    setInterestRate(state, action){
      state.interestRate = action.payload;
    },
    setInvoicesPaid(state, action){
      state.invoicesPaid = action.payload;
    },
    setInvoicesPaidId(state, action){
      state.invoicesPaidId = action.payload;
    },
    setBuyerList(state, action){
      state.buyerList = action.payload;
    },
    setpNumber(state, action){
      state.pNumber = action.payload;
    },
    setPsize(state, action){
      state.pSize = action.payload;
    },
  },
});

export const {setDiscountInvoices,
   setFinancialData, 
   setFinancialStatus, 
   setInvoicesList, 
   setInvoicesDue,
   setCommissionRate,
   setInterestRate,
   setInvoicesPaid, setBuyerList, setInvoicesDueId,setInvoicesPaidId,setpNumber, setPsize} = fundSlice.actions;
export default fundSlice.reducer;