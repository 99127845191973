/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';
import LocalStorageService from '../../services/LocalStorageService';

const initialState = {
  user: [],
  isLoggedIn: LocalStorageService.getAuthToken(),
  createdUser: '',
  userEmail:''
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setCreatedUser(state, action) {
      state.createdUser = action.payload;
    },
    setUserEmail(state, action) {
      state.userEmail = action.payload;
    },
  },
});

export const {setUser, setLoggedIn, setCreatedUser,setUserEmail} = userSlice.actions;
export default userSlice.reducer;
