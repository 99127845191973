import React, {useEffect,useRef,useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import { useIdleTimer } from 'react-idle-timer' 
import {Modal, Button} from 'antd';
import Layout from './components/Layout';
import Content from './components/Layout/Content';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import GlobalStyles from './GlobalStyles';
import ThemeProvider from './theme/ThemeProvider';
import 'antd/dist/antd.css';
import Routes from './routes';
import userApi from './Api/User';
import {setLoggedIn, setUser} from './store/reducers/userSlice';


const App: React.VFC = () => {
  const {isLoggedIn} = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sessionTimeOutRef:any = useRef(null)

  const getUser = async () => {
    const response = await userApi.fetchUser();
    if (response) {
      dispatch(setUser(response.data.financialInstitutionUser));
    }
  };
  const handleExit = () => {
    setIsModalOpen(false);
    dispatch(setLoggedIn(''));
  };

  const onIdle = () => {
    if(isLoggedIn){
      sessionTimeOutRef.current = setTimeout(handleExit, 5000)
      setIsModalOpen(true)
    }
  }

 
  const handleStay = () => {
    setIsModalOpen(false);
    clearTimeout(sessionTimeOutRef.current)
  };

  useEffect(() => {    
    if (!isLoggedIn) {
      userApi.logout();
      dispatch(setLoggedIn(''));
    }   
    try {
      if (isLoggedIn) {
        getUser();
      }
    } catch (e) {
      userApi.logout();
      dispatch(setLoggedIn(''));
    }
  }, [isLoggedIn]);
  
 
 

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 5 * 60,
    throttle: 500
  })
  
  useEffect(() => {
    const interval = setInterval(() => {
      getRemainingTime()
    }, 500)
    return () => {
      clearInterval(interval)
    }
  })

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Layout>
        {isLoggedIn ? <Header /> : null}
        <Content>
          <Routes />
        </Content>
        <Footer />
        <Modal  
          open={isModalOpen} 
          className='modal-login-logout'
          footer={[
            <Button key="back" style={{backgroundColor:'#504d4d'}} onClick={handleExit}>
              Oturumu kapat
            </Button>,
            <Button key="back" style={{backgroundColor:'#0d0f7b'}} onClick={handleStay}>
              Oturumu açık tut
            </Button>
          ]}
          >
            <p style={{fontSize:'16px', textAlign:'center', marginBottom:'0', fontWeight:'500'}}>Bir süredir hareketsizsiniz! Ne yapmak isterseniz?</p>
      </Modal>
      </Layout>
    </ThemeProvider>
  );
};

export default App;