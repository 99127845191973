import {apiV1, endpoints} from '..';
import {UpdateUserPayload} from './types';
import LocalStorageService from '../../services/LocalStorageService';

export default {
  async autoLogin(email: string, password: string) {
    return apiV1.post(endpoints.sessions, {email, password});
  },
  async fetchUser() {
    return apiV1.get(endpoints.financialUser);
  },
  async logoutApi() {
    return apiV1.post(endpoints.logout);
  },
  async logout() {
    LocalStorageService.removeAuthToken();
    LocalStorageService.removeNumber();
    LocalStorageService.removeSize();
    LocalStorageService.removeStatusCode();
    LocalStorageService.removeTaxId();
  },

  async updateUser(finanUserId : string ,updateUserPayload: UpdateUserPayload ) {
    return apiV1.patch(endpoints.corporationsUser(finanUserId), updateUserPayload);
  },
  async sendResetPasswordEmail(email: string) {
    return apiV1.post(endpoints.forgetPassword, {email});
  },
  async sendExpiredPasswordEmail(email: string) { 
    return apiV1.post(endpoints.expiredPassword, {email});
  },
  async sendLoginCodeEmail(email: string, code: string) {
    return apiV1.post(endpoints.codePassword, {email, code});
  },
  async updatePassword(data: any) {
    const {passwordUser,newPassword,passwordToken} = data;
    return apiV1.patch(endpoints.getCreatePassword(passwordUser), {newPassword,passwordToken} );
  },
  async sendTermsEmail(financialInstitutionUserId: string, terms: any) {
    return apiV1.post(endpoints.sendTermsEmail(financialInstitutionUserId), terms);
  },
  async passwordUpdate(data: any) {
    return apiV1.patch(endpoints.passwordUpdate, data );
  },
};
