import React, {useState} from 'react';
import {Row, Col, Form, Input, notification} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Card from '../../components/Card';
import Button from '../../components/Button';
import LoginStyles, {Logo} from './styles';
import LogoFonRadar from '../../assets/logo-big.png';
import paths from '../../routes/paths';
import userApi from '../../Api/User';
import {setLoggedIn, setUser,   setUserEmail} from '../../store/reducers/userSlice';
import LocalStorageService from '../../services/LocalStorageService';

interface LoginValues {
  email: string;
  password: string;
  id: string;
  financialInstitutionId: string;
}

const Login: React.VFC = () => {  
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onLogin = async (formValues: LoginValues) => {
    setLoading(true);
    try {
      const response = await userApi.autoLogin(formValues.email, formValues.password);
  
      if(response.data.token){
        dispatch(setLoggedIn(response.data.token));
        dispatch(setUser({
          email : response.data.email,
          id : response.data.id,
          financialInstitutionId : response.data.financialInstitutionId,
          taxId: response.data.taxId
        }));
        LocalStorageService.setTaxId(response.data.taxId)         
        notification.success({message: 'Hoş geldiniz!'});
        history.push(paths.default);

      }else{
        dispatch(setUserEmail(response.data))
        history.push(paths.twoFactor);  
      }
     
    }
    catch(e:any){
      if(e.data.Status === 401 && e.data.Type.includes('UserHasLockedException')){
        notification.error({message: 'Birden fazla başarısız giriş denemesi gerçekleştirdiniz. Lütfen 1 dakika sonra tekrar deneyiniz..'});
      }
      if(e.data.Status === 401 && e.data.Type.includes('AuthenticationException')){
        notification.error({message: 'Kullanıcı adınız ya da şifreniz hatalıdır.'});
      }
      if(e.data.Status === 404 && e.data.Type.includes('UserNotFoundException')){
        notification.error({message: 'Kullanıcı adınız ya da şifreniz hatalıdır.'});
      }
      if(e.data.Status === 403){
        notification.warning({
          message: `Devam edebilmek için şifrenizi değiştirmeniz gerekmektedir. Kayıtlı mailinizdeki bağlantıyı takip ederek şifrenizi değiştirebilirsiniz.`,
      });
        await userApi.sendExpiredPasswordEmail(formValues.email);
      }
    }
     finally {
      setLoading(false);
    }
  }


  return (
    <LoginStyles>
      <Row justify="center">
        <Col xs={24} md={12} lg={8}>
          <Card>
            <Logo className="mb-big" src={LogoFonRadar} alt="Depar.io" style={{width:'300px'}} />

            <Form name="login" onFinish={onLogin}>
              <Form.Item
                name="email"
                rules={[
                  {type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!'},
                  {
                    required: true,
                    message: 'Lütfen email giriniz!',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen şifrenizi giriniz!',
                  },
                ]}
              >
                <Input.Password placeholder="Şifre" />
              </Form.Item>

              <div className="text-center mb">
                <Link to={paths.passwordRecovery}>Şifremi Unuttum</Link>
              </div>

              <Form.Item className="m-0">
                <Button
                  className="mt"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  block
                >
                  Giriş Yap
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </LoginStyles>
  );
};

export default Login;
