import {endpoints, apiV1,generalApi} from '..';

export default {
  async fetchDiscountInvoices(pageNumber: any,pageSize: any) {
    try {
      const data = await apiV1.get(endpoints.getFinancialOffers(pageNumber,pageSize));
      return data;
    } catch(e) {
       return [];
    }
  },
  async fetchDiscountInvoiceById(id: any, discountId: any) {
    try {
      const data = await apiV1.get(endpoints.getFinancialDetail(id, discountId));
     
      return data;
    } catch(e) {
       return [];
      
    }
  },

  async makeOffer(discountId: any, offer: any) {
    return apiV1.post(endpoints.getCreatefinancialOffers(discountId), offer);
  },

  async setDiscountOffers(id: any) {
    return apiV1.post(endpoints.getDiscountOffer(id), {id});
  },

  async setInterestRate( values : any) {
    return apiV1.patch(endpoints.setInterestRate, values);
  },

  async fetchDueInvoices(filters: string, pageNumber:number, pageSize:number) {
    try{
      const response = await apiV1.get(endpoints.getDueInvoice(filters, pageNumber, pageSize));
      return response;
    }catch(e) {
      return [];
    }
  },
  async fetchPaidInvoices(filters: string, pageNumber:number, pageSize:number) {
    try{
      const response = await apiV1.get(endpoints.getPaidInvoice(filters, pageNumber, pageSize));
      return response;
    }catch(e) {
      return [];
    }
  },

  async fetchInterestRates(buyerId:string) {
    try{
      const response = await apiV1.get(endpoints.getCommissionInterest(buyerId));
      return response;
    }catch(e) {
      return [];
    }
  },
  
  async fetchBusinessDay(day: number, month: number, year: number) {
    try{
      const response = await generalApi.get(endpoints.businessDay(day, month, year));
      return response;
    }catch(e) {
      return [];
    }
  },


  async fetchMaturityRates(maturity:any) {
    try{
      const response = await apiV1.get(endpoints.setMaturityRates(maturity));
      return response;
    }catch(e) {
      return [];
    }
  },
  async setInvoicePayment(values: any) {
    return apiV1.patch(endpoints.invoicePayment, values);
  },

  async setInvoicePay( applicationNumber: string) {  
    return apiV1.patch(endpoints.invoicePay, {applicationNumber});
  },

  async setInvoicePayId( invoiceId: any, applicationNumber: string) {  
    return apiV1.patch(endpoints.invoicePayId(invoiceId), {applicationNumber});
  },

  async fetchBuyerList(filters: string, pageNumber:number, pageSize:number) {
    try{
      const response = await apiV1.get(endpoints.getBuyerList(filters, pageNumber, pageSize));
      return response;
    }catch(e) {
      return [];
    }
  },

  async getBuyerLists() {
    try{
      const response = await apiV1.get(endpoints.buyerList);
      return response;
    }catch(e) {
      return [];
    }
  },

  async setBuyerValor( buyerId: any, valor: number) {  
    return apiV1.patch(endpoints.buyerValor(buyerId), {delayValue: valor});
  },
  async setBuyerLimit( buyerId: any, limitData: number) {  
    return apiV1.patch(endpoints.buyerLimit(buyerId), {limit: limitData});
  },
  async setCancelDiscountApplication( discountapplication: any) {  
    return apiV1.patch(endpoints.cancelDiscountApplication(discountapplication));
  },

  async getInvoiceCalculate(applicationNumber: any, buyerId:any, invoices: any) {
    return apiV1.post(`discount-offer/${applicationNumber}/calculate`,{buyerId,  invoices});
  },

 
  async getXlsxDonwloadApi(discountApplicationId:any) {
    try{
      const response = await apiV1.get(endpoints.discountApplicationXlsx(discountApplicationId));
      return response;
    }catch(e) {
      return [];
    }
  },

};
