import React, {Dispatch, SetStateAction, useState} from 'react';
import {Divider, Popover} from 'antd';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {setLoggedIn} from '../../store/reducers/userSlice';
import Icon from '../Icon';
import AccountDropdownStyled, {AccountButton} from './styles';
import userApi from '../../Api/User';
import Text from '../Text';
import paths from '../../routes/paths';

const AccountMenu: React.FC<{setIsDropdownVisible: Dispatch<SetStateAction<boolean>>}> = ({
  setIsDropdownVisible,
}) => {
  const dispatch = useDispatch();
  const closeDropdown = () => {
    setIsDropdownVisible(false);
  };
  const onClickLogout = async () => {
    const response =  await userApi.logoutApi();
    if(response.status === 204){
      userApi.logout();
      dispatch(setLoggedIn(''));
    }
   
  };

  return (
    <>
      <Link to={paths.user} >
        <AccountButton onClick={closeDropdown} block>
          <Text className="m-0" type="h3" bold>
            Hesap Bilgileri
          </Text>
          <Text type="h4" color="smoke" bold>
            E-posta, telefon ve şifre bilgilerini değiştir.
          </Text>
        </AccountButton>
      </Link>
      <Link to={paths.passwordChange} >
        <AccountButton onClick={closeDropdown} block>
          <Text className="m-0" type="h3" bold>
            Şifre Değiştir
          </Text>
          <Text type="h4" color="smoke" bold>
            Şifre bilgilerinizi değiştirebilirsiniz.
          </Text>
        </AccountButton>
      </Link>

      <Divider className="m" />

      <div >
        <AccountButton onClick={onClickLogout} block>
          <Text className="m-0" type="h3" color="red" bold>
            Çıkış
          </Text>
        </AccountButton>
      </div>
    </>
  );
};

function AccountDropdown() {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <AccountDropdownStyled>
      <Popover
        className="cursor-pointer"
        content={() => <AccountMenu setIsDropdownVisible={setIsDropdownVisible} />}
        trigger="click"
        placement="bottomRight"
        onOpenChange={setIsDropdownVisible}
        open={isDropdownVisible}
      >
        <div>
          <Icon icon="user" size="lg" color="primary" />
          <Text color="primary" bold>
            Hesabım
          </Text>

          <Icon className="ml" icon="angle-down" size="lg" color="primary" />
        </div>
      </Popover>
    </AccountDropdownStyled>
  );
}

export default AccountDropdown;
