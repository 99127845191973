import styled from 'styled-components';


export const Logo = styled.img`
  width: 200px;
  display: block;
  margin: auto;
`;

export const  Cardpage = styled.div`
.ant-table-row{ cursor:pointer}
`;