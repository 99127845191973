import React, {forwardRef} from 'react';
import {DefaultTheme, StyledComponent} from 'styled-components';
import {Span, P, H1, H2, H3, H4, H5} from './styles';
import {Props} from './types';

interface TextMap {
  h1: StyledComponent<'h1', DefaultTheme, Props>;
  h2: StyledComponent<'h2', DefaultTheme, Props>;
  h3: StyledComponent<'h3', DefaultTheme, Props>;
  h4: StyledComponent<'h4', DefaultTheme, Props>;
  h5: StyledComponent<'h5', DefaultTheme, Props>;
  p: StyledComponent<'p', DefaultTheme, Props>;
  span: StyledComponent<'span', DefaultTheme, Props>;
}

const componentTypeMap: TextMap = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  p: P,
  span: Span,
};

const Text = forwardRef<null, Props>(
  (
    {
      type = 'span',
      color = 'text',
      weight,
      className,
      style,
      align,
      bold = false,
      underlined = false,
      block = false,
      children = null,
    },
    ref,
  ) => {
    const validType = componentTypeMap[type] ? type : 'span';
    const TextComponent: any = componentTypeMap[validType];

    return (
      <TextComponent
        ref={ref}
        color={color}
        bold={bold}
        weight={weight}
        className={className}
        style={style}
        align={align}
        block={block}
        underlined={underlined}
      >
        {children}
      </TextComponent>
    );
  },
);

export default Text;
