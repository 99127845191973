import {Color} from '../theme/index';

interface FundStatus {
  id: number;
  value: string;
  color: Color;
  text: string;
  desc: string;
}

export const fundTimeFilters = [
  {text: 'Bugün', value: 1},
  {text: 'Son 1 Hafta', value: 7},
  {text: 'Son 15 Gün', value: 15},
  {text: 'Son 1 Ay', value: 30},
  {text: 'Son 6 Ay', value: 180},
];

// WaitingOffer = new ("TEKLIF_BEKLIYOR","Teklif Bekliyor");
// OnProcess = new ("ISLEME_ALINDI","İşleme Alındı");
// Answered = new ("CEVAP_GELDI","Cevap Geldi");
// Approved = new ("ONAYLADIM","Onayladım");
// Expired = new ("SURESI_DOLDU","Süresi Doldu");
export const fundStatuses = [
  {
    id: 1,
    value: 'TEKLIF_BEKLIYOR',
    color: '#e7792b',
    text: 'Teklif Bekliyor',
    desc: 'Başvurunuz kurumlara başarı ile iletildi.',
  },
  {id: 2, value: 'CEVAP_GELDI', color: '#5ad363', text: 'Cevap Geldi'},
  {
    id: 3,
    value: 'SURESI_DOLDU',
    color: '#ff0000',
    text: 'Süresi Doldu',
    desc: '',
  },
  {id: 4, value: 'ONAYLADIM', text: 'Onayladım', color: 'primaryColor'},
  {
    id: 5,
    value: 'ISLEME_ALINDI',
    color: 'purple',
    text: 'İşleme Alındı',
    desc: 'Başvurunuz finansal kurumlar tarafından incelenmeye başlandı.',
  },
  {
    id: 6,
    value: 'KURUMLAR_INCELIYOR',
    color: 'geekblue',
    text: 'Kurumlar İnceliyor',
    desc: 'Başvurunuz finansal kurumlar tarafından incelenmeye başlandı.',
  },
  {
    id: 7,
    color: 'red',
    value: 'IPTAL_EDILDI',
    text: 'İptal Edildi',
    desc: 'Fatura işlemi iptal Edildi.',
  },
];

export const fundStatuses2 = [
  {
    id: 1,
    color: 'green',
    value: 'YENI_TALEP',
    text: 'Yeni Talep',
    desc: 'Satıcı tarafından gelen taleplere aynı gün içerisinde cevap vermeniz gerekmektedir.',
  },
  {
    id: 2,
    color: 'red',
    value: 'SURESI_DOLDU',
    text: 'Süresi Doldu',
    desc: 'Satıcımızın talebine teklif verme süresi doldu.',
  },
  {
    id: 3,
    color: 'blue',
    value: 'SATICI_TARAFINDAN_ONAYLANDI',
    text: 'Satıcı Tarafından Onaylandı',
    desc: 'Satıcımız teklifinizi onayladı. Temlikname yüklemesi için yönlendirdik.',
  },
  {
    id: 4,
    text: 'Satıcı Onayında',
    value: 'SATICI_ONAYINDA',
    color: 'geekblue',
    desc: 'Teklifiniz Satıcımız tarafından değerlendiriliyor.',
  },
  {
    id: 5,
    color: 'orange',
    value: 'TEKLIF_VEREMIYORUZ',
    text: 'Teklif Veremiyoruz',
    desc: 'Satıcıdan gelen talebe "Teklif Veremiyoruz" döndünüz.',
  },
  {
    id: 6,
    color: 'magenta',
    value: 'FARKLI_KURUM_TERCIH_EDILDI',
    text: 'Farklı Kurum Tercih Edildi',
    desc: 'Satıcımız başka kurumu tercih etti. Daha uygun teklifler sunarak fırsatları kaçırmayın.',
  },
  {
    id: 7,
    value: 'KURUMLAR_INCELIYOR',
    color: 'purple',
    text: 'Kurumlar İnceliyor',
    desc: 'Başvurunuz finansal kurumlar tarafından incelenmeye başlandı.',
  },
  {
    id: 8,
    color: '#e7792b',
    value: 'TEMLIK_EDILDI',
    text: 'Temlik Edildi',
    desc: 'Satıcımız imzalı temlikname yükledi, size eposta ile ilettik.',
  },
  {
    id: 9,
    color: 'red',
    value: 'IPTAL_EDILDI',
    text: 'İptal Edildi',
    desc: 'Fatura işlemi iptal Edildi.',
  },
];

export const fundStatusMap: Record<number, FundStatus> = fundStatuses.reduce(
  (prevStatuses, status) => ({
    ...prevStatuses,
    [status.value]: status,
  }),
  {},
);

export const fundStatusMap2: Record<number, FundStatus> = fundStatuses2.reduce(
  (prevStatuses, status) => ({
    ...prevStatuses,
    [status.id]: status,
  }),
  {},
);

export const fundStatusMap2Value: Record<number, FundStatus> = fundStatuses2.reduce(
  (prevStatuses, status) => ({
    ...prevStatuses,
    [status.value]: status,
  }),
  {},
);
