import React, {useState} from 'react';
import {useHistory, Link} from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import {Row, Col, Form, Input, notification} from 'antd';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import LoginStyles, {Logo} from '../styles';
import LogoFonRadar from '../../../assets/logo-big.png';
import userApi from '../../../Api/User';
import paths from '../../../routes/paths';
import {setLoggedIn, setUser} from '../../../store/reducers/userSlice';
import LocalStorageService from '../../../services/LocalStorageService';

const TwoFactor: React.VFC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {userEmail} = useSelector((state: any) => state.user);

  const onLogin = async (formValues: { code: string}) => {
    setLoading(true);
    try {
      if(userEmail && formValues.code ){
        const response = await userApi.sendLoginCodeEmail( userEmail ,formValues.code);  
        if(response){        
            dispatch(setLoggedIn(response.data.token));
            dispatch(setUser({
              email : response.data.email,
              id : response.data.id,
              financialInstitutionId : response.data.financialInstitutionId,
              taxId: response.data.taxId
            }));
            LocalStorageService.setTaxId(response.data.taxId)  
            notification.success({message: 'Hoş geldiniz!'});
            history.push(paths.default);       
        }
      }     
    } catch(e:any){
      if(e.status === 406){
        notification.error({message: 'Doğrulama kodunuz hatalıdır veya süresi dolmuştur.'});
      }
    }
     finally {
      setLoading(false);
    }
  };

  return (
    <LoginStyles>
      <Row justify="center">
        <Col xs={24} md={12} lg={8}>
          <Card>
            <Logo className="mb-big" src={LogoFonRadar} alt="Depar.io" />
            <Text type="h2" color="primary" align="center">
            Doğrulama kodunuz e-postanıza iletilmiştir.
            </Text>

            <Form name="twoFactor" onFinish={onLogin}>
              <Form.Item
                name="code"
                style={{marginBottom:'0'}}
                rules={[
                  {type: 'string', message: 'Lütfen, mailinize gelen doğrulama kodunu giriniz!'},
                  {
                    required: true,
                    message: 'Lütfen doğrulama kodunu giriniz!',
                  },
                ]}
              >
                <Input placeholder="Doğrulama Kodu" />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{marginTop:'20px'}}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  block
                >
                  Gönder
                </Button>
              </Form.Item>
            </Form>
            <Link to={paths.login}>Tekrar Doğrulama Kodu Al</Link>
          </Card>
        </Col>
      </Row>
    </LoginStyles>
  );
};

export default TwoFactor;
