import React, { useState, useEffect} from 'react';
import {Col, Row, Table, Button, Form, DatePicker, Input, notification } from 'antd';
import {  CheckCircleOutlined  } from "@ant-design/icons";
import moment from 'moment';
import {useSelector, useDispatch} from 'react-redux';
import Card from '../../components/Card';
import Text from '../../components/Text';
import financialsApi from '../../Api/Financials';
import {setInvoicesPaid, setInvoicesPaidId} from '../../store/reducers/financialSlice';
import '../../../node_modules/moment/locale/tr';
import locale from '../../../node_modules/antd/es/date-picker/locale/tr_TR';
import {convertToDotSeperated} from '../../utils/string';

const PendingCollection: React.VFC = () => {
// const [form] = Form.useForm();
const [isLoading, setIsLoading] = useState(true);
const [show, setShow] = useState(false)
const { invoicesPaid, invoicesPaidId} = useSelector((state: any) => state.financials);
const dispatch = useDispatch();
const [form] = Form.useForm();
const [psize, setPsize] = useState(10);
const [page, setpage] = useState(1);
const [totalDataCount, setTotalDataCount] = useState<any>(1);
const [filters, setFilters] = useState({
  title : '',
  invoiceTermBegin:'',
  invoiceTermEnd:'',
})


const sortDate = (a: any, b: any)=> {
  const dateA = new Date(a.discountApplicationDate).getTime();
  const dateB = new Date(b.discountApplicationDate).getTime();
  return dateA > dateB ? 1 : -1; 
}


const getPaidInvoice = async () => {   
  
    try {
      setIsLoading(true);
      const {  title,invoiceTermBegin, invoiceTermEnd} = filters;

      const filterStr = `
        ${title ? `title=${title}&` : ''}
        ${invoiceTermBegin ? `invoiceTermBegin=${invoiceTermBegin}&` : ''}
        ${invoiceTermEnd ? `invoiceTermEnd=${invoiceTermEnd}&` : ''} `
      ;
     
      const response = await financialsApi.fetchPaidInvoices(filterStr.trim().replaceAll(/\s/g,''), page, psize);      
      if (response) {        
        setIsLoading(false);
        dispatch(setInvoicesPaid(response.data.discountApplications.data.sort(sortDate)));
        setTotalDataCount(response.data.discountApplications.totalDataCount);
        dispatch(setInvoicesPaidId(response.data.discountApplications.data.map((item:any) => {
          return item.prePaidApplicationInvoices
        }))) 
      } else {        
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }; 

  const editPaidInvoiceId = async (row: any) => { 
    try{
      setShow(row.id)
      const aNumber = invoicesPaid && invoicesPaid.filter((item:any) =>  {
       return item.prePaidApplicationInvoices.find((i:any) => i.id === row.id )
      })
      const response = await financialsApi.setInvoicePayId(row.id,  aNumber[0].applicationNumber)    
      if (response) {
        getPaidInvoice();   
        }
    } catch(e:any){
      setIsLoading(false);
      if(e.data.Status === 406 && e.data.Type.includes('InvoiceAlreadyPaidException') ){
        notification.warning({
          message: 'Bu başvuru için daha önce tahsilat gerçekleştirilmiştir.',
        });
      }
    }
   
  }

  // const editPaidInvoice = async ( aNumber: string) => { 
  //   const response = await financialsApi.setInvoicePay(aNumber) 
  //   if (response) {
  //      getPaidInvoice();   
  //     }
  // }


//   const  disabledDate = (current: any) => {  
//     return current && current < moment().startOf("day")
// }

const onchangeValue = async (values: any) => {     
  setFilters({
     ...filters,
    title : values.title === undefined ? '' : values.title,
    invoiceTermBegin : values.startDate === undefined ? '' : moment.utc(values.startDate).format() ,
    invoiceTermEnd : values.endDate === undefined ? '' :   moment.utc(values.endDate).format(),
  });
  form.resetFields();
  
  // eslint-disable-next-line no-console
};

 useEffect(() => {
  getPaidInvoice();    
  }, [filters, page, psize]);
  

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };
  const columns = [
    {
     title: "Fatura No",
     dataIndex: "invoiceNumber",
     key: "invoiceNumber",
   },
   {
     title: "Fatura Tarihi",
     dataIndex: "invoiceDate",
     key: "invoiceDate",
     render: (date: any) => moment(date).format('DD-MM-YYYY'),
   },
   {
     title: "Fatura Tutarı",
     dataIndex: "invoiceAmount",
     key: "invoiceAmount",
     render: (price: number) => `${convertToDotSeperated(price)} ₺`,
   },
   {
     title: "Fatura Vadesi",
     dataIndex: "invoiceTerm",
     key: "invoiceTerm",
     render: (date: any) => moment(date).format('DD-MM-YYYY'),      
   },  
   {
    title:'Ödeme Onayı',
    dataIndex:'uedit',
    key:'uedit',     
    render: (text: any, row: any) =>( 
    show === row.id ?  
      <Button  style={{backgroundColor:'green', color:'#fff'}}> <CheckCircleOutlined/>Tahsilat Yapıldı </Button>      
      :
      <Button type="primary" 
        onClick={() => editPaidInvoiceId(row)}>Tahsilat Yapıldı</Button>
    )        
},   
   ];

  const tableCols: any = [
    {
      title: "Başvuru No",
      dataIndex: "applicationNumber",
      key: "applicationNumber",
      fixed: 'left',
      width:100
    },
    {
      title: "Başvuru Tarihi",
      dataIndex: "discountApplicationDate",
      key: "discountApplicationDate",
      render: (date: any) => moment(date).format('DD-MM-YYYY'),
      width:120
    },
    {
      title: "Alıcı VKN",
      dataIndex: "buyerTaxNumber",
      key: "buyerTaxNumber",
      width:120
    },
    {
      title: "Alıcı Ünvanı",
      dataIndex: "buyerTitle",
      key: "buyerTitle",
    },
    {
      title: "Tedarikçi VKN",
      dataIndex: "supplierTaxNumber",
      key: "supplierTaxNumber",
      width:120
    },
    {
      title: "Tedarikçi Ünvanı",
      dataIndex: "supplierTitle",
      key: "supplierTitle",
    },
  //   {
  //     title:'Ödeme Onayı',
  //     dataIndex:'uedit',
  //     key:'uedit',     
  //     render: (text: any, row: any) =>( 
  //     show === row.id ?  
  //       <Button  style={{backgroundColor:'green', color:'#fff'}}> <CheckCircleOutlined/>Hepsi Tahsil Edildi  </Button>      
  //       :
  //       <Button type="primary" 
  //         onClick={() => editPaidInvoice( row.applicationNumber)}>Hepsi Tahsil Edildi</Button>
  //     )        
  // },
  ]; 
 
 
  return (
    <>
      <Card>
        <Row gutter={20} align="middle" style={{marginBottom:'50px'}}>
          <Col>
            <Text className="m-0 page-title" type="h1" color="primary" bold>
            Tahsilat Onayı Bekleyen Faturalar          
            </Text>
          </Col>
        </Row> 
       
        <Row>
          <Col >
            <Form
               name="filter-box"
               onFinish={onchangeValue}
               autoComplete="off"
               labelCol={{
                 span: 8,
               }}
               size = "large"
               style={{
                 minWidth: 400,
               }}
            >
              <div className='filter-row'>
                <div>
                    <span>Vade Başlangıç</span>
                    <Form.Item
                      name='startDate'
                    >
                      <DatePicker  locale={locale} format='DD/MM/YYYY'   placeholder='Tarih Seçiniz'  />
                    </Form.Item>                  
                </div>
                <div>
                    <span>Vade Bitiş</span>
                    <Form.Item
                      name='endDate'
                    >
                      <DatePicker locale={locale} format='DD/MM/YYYY'  placeholder='Tarih Seçiniz'  />
                    </Form.Item>                  
                </div>
                <div>
                  <span>Ünvan</span>
                  <Form.Item name="title" >
                    <Input 
                      placeholder="Alıcı veya Tedarikçi Adı Girin"
                     />
                    </Form.Item>
                </div>
                {/* <div>
                  <Form.Item name='check' valuePropName="checked">
                    <Checkbox 
                      name='check'
                     >Vadesi Geçmiş Faturaları Göster</Checkbox>
                  </Form.Item>
                </div> */}
                <div>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"                 
                    >Filtrele</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Col>
        </Row>     
        <Row >
      
        <Table
          columns={tableCols}
          style={{ width:'100%'}}
          rowKey= {(record : any, index:any) => index}
          size='middle'
          loading={isLoading}
          expandable={{
            rowExpandable : (record:any) => record,
            expandedRowRender : (record:any, index:any) => {
              return <Table columns={columns} dataSource={invoicesPaidId[index]} pagination={false} />
            },
            defaultExpandedRowKeys: ['1'],
          }}
          dataSource={invoicesPaid}
          pagination={{
            position: ["topLeft", "none" as any],
            showLessItems: true,                          
            showSizeChanger: true,
            current: page,
            pageSize:psize,
            total:totalDataCount, 
            onChange: handleShowSize,                                        
          }}
          scroll={{
            x: 1100,
          }} 
        />        
        </Row> 
      </Card>
    </>
  );
};

export default PendingCollection;
