import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Row, Col, Form, Input, notification} from 'antd';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import LoginStyles, {Logo} from '../styles';
import LogoFonRadar from '../../../assets/logo-big.png';
import userApi from '../../../Api/User';
import paths from '../../../routes/paths';

const PasswordRecovery: React.VFC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onResetPass = async (formValues: {email: string}) => {
    setLoading(true);
    try {
      await userApi.sendResetPasswordEmail(formValues.email);
      notification.success({message: 'Lütfen mailinizi kontrol ediniz!'});
      history.push(paths.login);
    }catch(e:any){
      if(e.data.Status === 404 && e.data.Type.includes('FinancialInstitutionUserNotFoundException')){
        notification.error({message: 'Kullanıcı email adresi hatalıdır.'});
      }
    }
     finally {
      setLoading(false);
    }
  };

  return (
    <LoginStyles>
      <Row justify="center">
        <Col xs={24} md={12} lg={8}>
          <Card>
            <Logo className="mb-big" src={LogoFonRadar} alt="Depar.io" />
            <Text type="h2" color="primary" align="center">
              Şifremi Unuttum
            </Text>

            <Form name="passwordRecovery" onFinish={onResetPass}>
              <Form.Item
                name="email"
                rules={[
                  {type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!'},
                  {
                    required: true,
                    message: 'Lütfen email giriniz!',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  block
                >
                  Gönder
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </LoginStyles>
  );
};

export default PasswordRecovery;
