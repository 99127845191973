import React, {useState} from 'react';
import {Col, Row, Spin} from 'antd';
import {useSelector} from 'react-redux';
// import Button from '../../components/Button';
import Card from '../../components/Card';
import Text from '../../components/Text';
// import Icon from '../../components/Icon';
import UpdateUserForm from './UpdateUserForm';
import ContentContainer from '../../components/ContentContainer';

const UserDetail: React.VFC = () => {
  const {user} = useSelector((state: any) => state.user);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);


  return (
    <>
      <Card>
        <Row gutter={20} align="middle">
          <Col>
            <Text className="m-0" type="h1" color="primary" bold>
              Hesabım
            </Text>
          </Col>
          {/* {!isUpdatingUser && (
            <Col>
              <Button onClick={() => setIsUpdatingUser(true)}>
                <Icon icon="pen" color="inherit" />
                Duzenle
              </Button>
            </Col>
          )} */}
        </Row>

        <ContentContainer type="inner">
          {isUpdatingUser ? (
            <UpdateUserForm onSuccess={() => setIsUpdatingUser(false)} />
          ) : (
            <Spin spinning={isUpdatingUser}>
              <Row className="mt" gutter={[10, 20]}>
                <Col xs={24} lg={12} xl={8}>
                  <Text color="smoke" block>
                    Adı:{' '}
                  </Text>
                  <Text bold>{user.name}</Text>
                </Col>

                <Col xs={24} lg={12} xl={8}>
                  <Text color="smoke" block>
                    Soyadı:{' '}
                  </Text>
                  <Text bold>{user.surname} </Text>
                </Col>

                <Col xs={24} lg={12} xl={8}>
                  <Text color="smoke" block>
                    Unvan:{' '}
                  </Text>
                  <Text bold>{user.financialInstitutionName || '-'}  </Text>
                </Col>

                <Col xs={24} lg={12} xl={8}>
                  <Text color="smoke" block>
                    Telefon Numarası:{' '}
                  </Text>
                  <Text bold>{user.gsmNumber}</Text>
                </Col>

                <Col xs={24} lg={12} xl={8}>
                  <Text color="smoke" block>
                    E-posta adresi:{' '}
                  </Text>
                  <Text bold>{user.email}</Text>
                </Col>

                <Col xs={24} lg={12} xl={8}>
                  <Text color="smoke" block>
                    Şifre:{' '}
                  </Text>
                  <Text bold>********</Text>
                </Col>
              </Row>
            </Spin>
          )}
        </ContentContainer>
      </Card>
    </>
  );
};

export default UserDetail;
