class LocalStorageService {
  constructor() {
    this.authTokenKey = 'depar-finance-auth';
    this.number = 'number';
    this.size = 'size';
    this.statusCode = 'statusCode';
    this.taxId = 'taxId'
  }

  getAuthToken() {
    return localStorage.getItem(this.authTokenKey);
  }

  setAuthToken(token) {
    localStorage.setItem(this.authTokenKey, `${token}`);
  }

  removeAuthToken() {
    localStorage.removeItem(this.authTokenKey);
  }

  getTaxId() {
    return localStorage.getItem(this.taxId);
  }

  setTaxId(data) {
    localStorage.setItem(this.taxId, `${data}`);
  }

  removeTaxId() {
    localStorage.removeItem(this.taxId);
  }

  getNumber() {
    return localStorage.getItem(this.number);
  }
  setNumber(number) {
    localStorage.setItem(this.number, number);
  }
  removeNumber() {
    localStorage.removeItem(this.number);
  }

  getSize() {
    return localStorage.getItem(this.size);
  }
  setSize(size) {
    localStorage.setItem(this.size, size);
  }
  removeSize() {
    localStorage.removeItem(this.size);
  }
  setStatusCode(statusCode) {
    localStorage.setItem(this.statusCode, statusCode);
  }
  getStatusCode() {
    return localStorage.getItem(this.statusCode);
  }
  removeStatusCode() {
    localStorage.removeItem(this.statusCode);
  }
}

export default new LocalStorageService();
