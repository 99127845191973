import React, { useState, useEffect} from 'react';
import {Col, Row, Table, Button, Form,  Input, InputNumber, notification } from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import Card from '../../components/Card';
import Text from '../../components/Text';
import financialsApi from '../../Api/Financials';
import {setBuyerList} from '../../store/reducers/financialSlice';
import {convertToDotSeperated} from '../../utils/string';

const buyerTransactions: React.VFC = () => {
 const [form] = Form.useForm();
const [isLoading, setIsLoading] = useState(true);
const { buyerList} = useSelector((state: any) => state.financials);
const dispatch = useDispatch();
const [filterForm] = Form.useForm();
const [psize, setPsize] = useState(10);
const [page, setpage] = useState(1);
const [totalDataCount, setTotalDataCount] = useState<any>(1);
const [searchQuery, setSearchQuery] = useState('');
const [limit, setLimit] = useState('');
const [valor, setValor] = useState('');


const getBuyerList = async () => {  
    try {
      setIsLoading(true);
      const response = await financialsApi.fetchBuyerList(searchQuery, page, psize);  
      if (response) {
        setIsLoading(false);
        dispatch(setBuyerList(response.data.buyers.data));       
        setTotalDataCount(response.data.buyers.totalDataCount);
        filterForm.resetFields(['title']);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };
  

const onchangeValue = (e:any) => {
  setSearchQuery(e.title === undefined ? '': e.title)
}

const updateBuyerValor = async (value :any, row:any) => {    
  
  if(value !== ''){
    const response = await financialsApi.setBuyerValor(row.id, value) 
    if (response) {
      notification.success({message: 'Valor başarıyla güncellenmiştir.'});
      getBuyerList(); 
      window.location.reload();  
      }
  }
 else{
  notification.success({message: 'Güncellenecek valor değerini girmediniz.'});
 }
  }
  const updateBuyerLimit = async (value :any, row:any) => {  
    try{
      if(value !== ''){
        const response = await financialsApi.setBuyerLimit(row.id, value)   
        if (response) {
          notification.success({message: 'Limit başarıyla güncellenmiştir.'});
          getBuyerList(); 
          window.location.reload();  
          }
      }else{
        notification.success({message: 'Güncellenecek limit değerini girmediniz.'});
      } 

    }catch(e:any){
      if(e.data.Status === 406 && e.data.Type.includes('BuyerLimitMustBeGreaterThanOrEqualTotalRiskException')){
        notification.error({message: 'Limit mevcut riskten az bir değer olamaz.'});
      }
    }
       
  }

 useEffect(() => {
  getBuyerList();    
  }, [searchQuery,psize,page]);

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };

  const tableCols: any = [
    {
      title: "VKN",
      dataIndex: "taxId",
      key: "taxId",
      fixed: 'left',
    },
    {
      title: "Alıcı Ünvanı",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Risk",
      dataIndex: "risk",
      key: "risk",
      width:150,
      render : (value: any) => `${convertToDotSeperated(value)} ₺`
    },
    {
      title: 'Limit',
      dataIndex: 'limit',
      key: 'limit', 
      width:300,    
      render: (value :any, row : any) => {
        return <Form form={form} className="table-limit-input" style={{ display : 'flex'}}>
                <Form.Item 
                    name={`${row.id}limit`} 
                    className='hide-arrow'
                  >
                  <InputNumber
                   // formatter={(val) => ` ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(val:any) => val.replace(/\$\s?|(,*)/g, '')}
                    placeholder={`${convertToDotSeperated(value)} ₺`}                     
                    onChange={(val:any) => setLimit(val)} 
                    className="no-spin input-width"                   
                    type='text' 
                                       
              /> 
              </Form.Item>
              <Form.Item>              
                <Button className='filter-row-button' style={{marginLeft:'20px'}}  
                    onClick={() => updateBuyerLimit(limit, row) } >Güncelle</Button>
              </Form.Item>
            </Form>    
      }
     },
    {
      title: 'Valör Gün Sayısı',
      dataIndex: 'delayValue',
      key: 'delayValue',
      render: (value :any, row : any) => {
        return <Form form={form} className="table-limit-input" style={{ display : 'flex'}}>
                <Form.Item name={`${row.id}valor`} 
                  style={{ width:'60px'}}                 >
                <Input                
                  placeholder={row.delayValue}  
                  name={row.id} 
                  type="number"
                  onChange={(e:any) => setValor(e.target.value)} 
                  className="no-spin input-width"
                  max={3}
                  min={0}
                />           
              </Form.Item>
              <Form.Item>
                <Button className='filter-row-button' style={{marginLeft:'20px'}}  
                    onClick={() => updateBuyerValor(valor, row) } >Güncelle</Button>
              </Form.Item>
            </Form>    
      }
    }, 
  ];  


 
  return (
    <>
      <Card>
        <Row gutter={20} align="middle" style={{marginBottom:'50px'}}>
          <Col>
            <Text className="m-0 page-title" type="h1" color="primary" bold>
              Alıcı Limit/Valör Tanımlama         
            </Text>
          </Col>
        </Row> 
       
        <Row>
          <Col >
            <Form
              form={filterForm}
               name="filter-box"
               onFinish={onchangeValue}
               autoComplete="off"
               labelCol={{
                 span: 8,
               }}
               size = "large"
               style={{
                 minWidth: 400,
               }}
            >
              <div className='filter-row'>                
                <div>
                  <Form.Item name="title" >
                    <Input 
                      placeholder="Alıcı Ünvanı veya VKN'si Giriniz"
                      style={{minWidth:'280px'}}
                     />
                    </Form.Item>
                </div>               
                <div>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"                 
                    >Filtrele</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Col>
        </Row>     
        <Row >
            <Table
              style={{ width:'100%'}}
              className='two-color-table'
                rowKey="id"
                size='middle'
                loading={isLoading}
                dataSource={buyerList.filter((item: any) => !!item.isDefinedForFinancialInstitution )}
                columns={tableCols} 
                pagination={{
                  position: ["topLeft", "none" as any],
                  showLessItems: true,                          
                  showSizeChanger: true,
                  current: page,
                  pageSize:psize,
                  total:totalDataCount, 
                  onChange: handleShowSize,                                        
                }} 
                scroll={{
                  x: 1000,
                }}   
            />         
        </Row> 
      </Card>
    </>
  );
};

export default buyerTransactions;
