import React from 'react';
import {Card, Col, Divider, Row, Skeleton} from 'antd';
import ContentContainer from '../ContentContainer';

const DetailPlaceholder: React.VFC = () => {
  return (
    <Card style={{borderLeftColor: 'smoke'}}>
      <ContentContainer type="inner" verticalPadding={false}>
        <Skeleton.Input className="w-100" active />

        <Divider />

        <Row>
          <Skeleton.Button className="mr" size="large" active />
          <Skeleton.Button size="large" active />
        </Row>

        <Divider />

        <Row gutter={[10, 20]}>
          <Col xs={24} lg={12} xl={8}>
            <Skeleton paragraph={{rows: 1}} />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Skeleton paragraph={{rows: 1}} />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Skeleton paragraph={{rows: 1}} />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Skeleton paragraph={{rows: 1}} />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Skeleton paragraph={{rows: 1}} />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Skeleton paragraph={{rows: 1}} />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Skeleton paragraph={{rows: 1}} />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Skeleton paragraph={{rows: 1}} />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Skeleton paragraph={{rows: 1}} />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Skeleton.Input className="w-100" active />
          </Col>
        </Row>
      </ContentContainer>
    </Card>
  );
};

export default DetailPlaceholder;
