import React,{useState} from 'react';
import {Col, Row, Dropdown, Drawer, Typography, Collapse} from 'antd';
import {  UserOutlined } from "@ant-design/icons";
import {Link, useLocation} from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import ContentContainer from '../../ContentContainer';
import {HeaderContainer, Logo, StyledHeader} from './styles';
import Button from '../../Button';
import AccountDropdown from '../../AccountDropdown';
import paths from '../../../routes/paths';
import LogoPng from '../../../assets/logo.png';
import userApi from '../../../Api/User';
import {setLoggedIn} from '../../../store/reducers/userSlice';


const items = [
  {
    key: '1',
    label: (
      <Link to={paths.dueInvoices}>
        <Button
          className="mr"
          type='link'
          size="large"
        >
        Ön Ödeme Onayı Bekleyen
        </Button>
    </Link>
    ),
  },
  {
    key: '2',
    label: (
      <Link to={paths.pendingCollection}>
        <Button
          className="mr"
          type='link'
          size="large"
        >
        Tahsilat Onayı Bekleyen
        </Button>
    </Link>
    ),
  },
];



const Header: React.VFC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;
  const { user} = useSelector((state: any) => state.user);
  const [navShow, setNavShow] = useState(false);

  const { Text } = Typography;
  const { Panel } = Collapse

  const showDrawer = () => {
    setNavShow(true);
  };
  const onClose = () => {
    setNavShow(false);
  };
  const onClickLogout = async () => {
    const response =  await userApi.logoutApi();
    if(response.status === 204){
      userApi.logout();
      dispatch(setLoggedIn(''));
    }
  };
 
  return (
    <HeaderContainer>
      <StyledHeader>
        <ContentContainer verticalPadding={false} className="h-100">
          <Row className="h-100 header-menu" justify="space-between" align="middle" gutter={20}>
            <Col >
              <Link to={paths.default} >
                <Logo src={LogoPng} alt="Depar.io"   />
              </Link>
            </Col>
            <Col  className='trans-drop custom-menu'>
                <Link to={paths.default} >
                  <Button
                    className="mr"
                    type={paths.default === currentPath ? 'primary' : 'link'}
                    size="large"
                  >
                   Tedarikçi Talepleri
                  </Button>
                </Link> 
                
                <Dropdown                 
                  menu={{
                    items,
                  }}
                  placement="bottom"
                  arrow
                >
                  <Button
                  className="mr"
                  type='link'
                  size="large"
                 
                  >Fatura İşlemleri</Button>
                </Dropdown>
               
                <Link  to={paths.dailyRates}>
                  <Button
                    className="mr"
                    type={paths.dailyRates === currentPath ? 'primary' : 'link'}
                    size="large"
                  >
                   Faiz Oranları
                  </Button>
                </Link> 

                <Link  to={paths.buyerTransactions}>
                  <Button
                    className="mr"
                    type={paths.buyerTransactions === currentPath ? 'primary' : 'link'}
                    size="large"
                  >
                   Alıcı İşlemleri
                  </Button>
                </Link>  

            </Col>
            
            <Button className={`menu-toggle  ${navShow ? 'open': ''}`}  onClick={showDrawer} >
              <span className='hamburger'/>
            </Button>
            <Drawer
              title={
                <Link to={paths.login}>
                    <Logo src={LogoPng} alt="Depar.io" style={{width:'160px'}}   />
                </Link>
                }
                placement="left"
                width={350}
                onClose={onClose}
                open={navShow}
                >
                <div className="mobil-userName">
                  <div className="left">
                    <UserOutlined />
                    <p>{user.email}</p>
                  </div>
                  <div style={{textAlign:'right'}}>
                    <Button onClick={onClickLogout}>
                        <Text className='m-0' style={{ color: 'red' }}>
                            Çıkış
                        </Text>
                      </Button>
                  </div>
                </div>
                <div className="mobil-menu">
                  <Link to={paths.default}>
                    Tedarikçi Talepleri     
                   </Link>
                   <Collapse defaultActiveKey={['1']} expandIconPosition="right" >
                        <Panel header="Fatura İşlemleri" key="1" >
                          <Link to={paths.dueInvoices}>
                            Ön Ödeme Onayı Bekleyen   
                          </Link>
                          <Link to={paths.pendingCollection}>
                            Tahsilat Onayı Bekleyen
                          </Link>
                        </Panel>
                    </Collapse>
                   <Link to={paths.dailyRates}>
                    Faiz Oranları     
                   </Link>
                   <Link to={paths.buyerTransactions}>
                      Alıcı İşlemleri    
                   </Link>
                   <Collapse defaultActiveKey={['1']} expandIconPosition="right" >
                        <Panel header="Hesabım" key="1" >
                          <Link to={paths.user}>
                            Hesap Bilgileri
                          </Link>
                          <Link to={paths.passwordChange}>
                            Şifre Değiştir
                          </Link>
                        </Panel>
                    </Collapse> 
                    
                </div>
            </Drawer>

            <Col className='custom-menu' >
              <AccountDropdown/>
            </Col>
          </Row>
        </ContentContainer>
      </StyledHeader>
    </HeaderContainer>
  );
};

export default Header;
