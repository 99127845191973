import React from 'react';
import Content from './styles';
import Props from './types';

const ContentContainer: React.FC<Props> = (props) => {
  const {children, type = 'default', verticalPadding = true, ...restProps} = props;

  return (
    <Content type={type} verticalPadding={verticalPadding} {...restProps}>
      {children}
    </Content>
  );
};

export default ContentContainer;
