import styled, {css, DefaultTheme} from 'styled-components';
import {Props, TextType} from './types';

const getFontWeight = (
  textType: TextType,
  isBold: boolean | undefined,
  weight: string | number | undefined,
  theme: DefaultTheme,
) => {
  if (isBold) return 500;
  if (weight) return weight;

  switch (textType) {
    case 'h1':
      return theme.main.titleFontWeight;
    default:
      return theme.main.textFontWeight;
  }
};
const getFontSize = (type: TextType, theme: DefaultTheme) => {
  switch (type) {
    case 'h1':
      return theme.main.h1FontSize;
    case 'h2':
      return theme.main.h2FontSize;
    case 'h3':
      return theme.main.h3FontSize;
    case 'h4':
      return theme.main.h4FontSize;
    case 'h5':
      return theme.main.h5FontSize;
    default:
      return theme.main.textFontSize;
  }
};

const createTextStyles = (type: TextType) => css<Props>`
  font-size: ${({theme}) => getFontSize(type, theme)};

  font-weight: ${({theme, bold, weight}) => getFontWeight(type, bold, weight, theme)};
  text-align: ${({align}) => align};
  text-decoration: ${({underlined}) => (underlined ? 'underline' : '')};

  color: ${({theme, color}) => theme.getThemedColor(color)};
  display: ${({block}) => (block ? 'block' : '')};
`;

export const Span = styled.span`
  ${() => createTextStyles('span')}
`;
export const P = styled.p`
  ${() => createTextStyles('p')}
`;
export const H1 = styled.h1`
  ${() => createTextStyles('h1')}
`;
export const H2 = styled.h2`
  ${() => createTextStyles('h2')}
`;
export const H3 = styled.h3`
  ${() => createTextStyles('h3')}
`;
export const H4 = styled.h4`
  ${() => createTextStyles('h4')}
`;
export const H5 = styled.h5`
  ${() => createTextStyles('h5')}
`;
